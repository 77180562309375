.whatsapp-button {
  min-width: 2.75rem;
  max-width: 2.75rem;
  min-height: 2.75rem;
  max-height: 2.75rem;
  position:fixed;
  width:44px;
  height:44px;
  color:#FFF;
  border-radius:50%;
  text-align:center;
  font-size:30px;
  z-index:1000;
  background-color: #25d366;
  bottom: 35px;
  right:35px;
}

@media (max-width: 800px) {
  .whatsapp-button {
    min-width: 3rem;
    max-width: 3rem;
    min-height: 3rem;
    max-height: 3rem;
    right: 10px;
    bottom: 10px;
  }

  .whatsapp-button > i {
    font-size: 2.25rem;
    transform: translateY(0.25rem);
  }
}