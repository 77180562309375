body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.button.primary {
  background-color: rgb(95, 203, 207) !important;
  color: white !important;
}

.button-orange {
  background-color: rgba(247, 78, 0, 0.86) !important;
  color: #ffffff !important;
}

.button-orange:hover,
.button-orange:active {
  background-color: #f74e00 !important;
}

.circle.notch {
  -webkit-animation: spin 0.5s linear infinite;
  -moz-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
  background-color: transparent !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
